import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateFormData, updateTotalPrice, resetFormData } from '../redux/formSlice';
import axios from 'axios';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import FormStep1 from './step1/FormStep1';
import FormStep2Branding from './step2/FormStep2Branding';
import FormStep2UiUx from './step2/FormStep2UiUx';
import FormStep2Marketing from './step2/FormStep2Marketing';
import FormStep2Seo from './step2/FormStep2Seo';
import FormStep2Website from './step2/FormStep2Website';
import FormStep2Maintenance from './step2/FormStep2Maintenance';
import FormStep3 from './step3/FormStep3';

import './FormContainer.scss';

const FormContainer = () => {
  const dispatch = useDispatch();
  const formData = useSelector((state) => state.form);
  const currentStep = useSelector((state) => state.form.currentStep);
  const totalPrice = useSelector((state) => state.form.totalPrice);

  const [calculatedPrice, setCalculatedPrice] = useState(0);

  useEffect(() => {
    const fetchPrice = async () => {
      try {
        const calculatedPrice = await calculatePrice(formData);
        setCalculatedPrice(calculatedPrice);
        dispatch(updateTotalPrice(calculatedPrice));
      } catch (error) {
        console.error('Erreur lors du calcul du prix:', error);
      }
    };
    fetchPrice();
  }, [formData, dispatch]);

  const progressPercentage = (currentStep / 3) * 100;

  const nextStep = () => {
    if (currentStep < 3) {
      dispatch(updateFormData({ currentStep: currentStep + 1 }));
    }
  };

  const previousStep = () => {
    if (currentStep > 1) {
      dispatch(updateFormData({ currentStep: currentStep - 1 }));
    }
  };

  const updateFormDataHandler = (data) => {
    dispatch(updateFormData(data));
  };

  const calculatePrice = async (formData) => {
    try {
      console.log('Données envoyées pour calcul du prix:', formData);
      const response = await axios.post('/api/projects/calculate-price', formData);
      if (response.status === 200) {
        return response.data.estimatedPrice;
      } else {
        console.error('Erreur lors du calcul du prix:', response.status);
        return 0;
      }
    } catch (error) {
      if (error.response) {
        console.error('Erreur réponse du serveur :', error.response.data);
        console.error('Code d\'erreur du serveur :', error.response.status);
        console.error('En-têtes de la réponse :', error.response.headers);
      } else if (error.request) {
        console.error('La requête a été envoyée mais aucune réponse n\'a été reçue :', error.request);
      } else {
        console.error('Erreur lors de la création de la requête :', error.message);
      }
      return 0;
    }
  };
  

  const submitForm = async () => {
    try {
      const response = await axios.post('/api/projects', formData);
      if (response.status === 201) {
        toast.success('Nous avons bien reçu votre demande. Merci !');
        dispatch(resetFormData());
      } else {
        toast.error('Erreur lors de la création du projet. Veuillez réessayer.');
      }
    } catch (error) {
      if (error.response) {
        toast.error(`Erreur : ${error.response.data.message || 'Une erreur s\'est produite, veuillez réessayer.'}`);
      } else if (error.request) {
        toast.error('La requête a été envoyée mais aucune réponse n\'a été reçue. Veuillez vérifier votre connexion.');
      } else {
        toast.error(`Erreur lors de la création de la requête : ${error.message}`);
      }
    }
  };

  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return <FormStep1 nextStep={nextStep} updateFormData={updateFormDataHandler} />;
      case 2:
        switch (formData.typeProject) {
          case 'branding':
            return (
              <FormStep2Branding
                nextStep={nextStep}
                previousStep={previousStep}
                updateFormData={updateFormDataHandler}
              />
            );
          case 'ui_ux':
            return (
              <FormStep2UiUx
                nextStep={nextStep}
                previousStep={previousStep}
                updateFormData={updateFormDataHandler}
              />
            );
          case 'marketing':
            return (
              <FormStep2Marketing
                nextStep={nextStep}
                previousStep={previousStep}
                updateFormData={updateFormDataHandler}
              />
            );
          case 'seo':
            return (
              <FormStep2Seo
                nextStep={nextStep}
                previousStep={previousStep}
                updateFormData={updateFormDataHandler}
              />
            );
          case 'website':
            return (
              <FormStep2Website
                nextStep={nextStep}
                previousStep={previousStep}
                updateFormData={updateFormDataHandler}
              />
            );
          case 'maintenance':
            return (
              <FormStep2Maintenance
                nextStep={nextStep}
                previousStep={previousStep}
                updateFormData={updateFormDataHandler}
              />
            );
          default:
            return <div>Erreur : Projet inconnu</div>;
        }
      case 3:
        return <FormStep3 previousStep={previousStep} submitForm={submitForm} updateFormData={updateFormDataHandler} />;
      default:
        return <div>Erreur : Étape inconnue</div>;
    }
  };

  return (
    <div className="formContainer">
      <ToastContainer />
      <h1>Formulaire d'Estimation Gratuite</h1>
      <section className='infos'>
        <div
          className="progressBar"
          style={{
            width: `${progressPercentage}%`,
          }}
        ></div>
        <div className="progressPercentage">
          {progressPercentage.toFixed(0)}% terminé - 
          <p className='estimatedPrice'>Tarif estimé : {totalPrice} €</p>
        </div>
      </section>
      {renderStep()}
    </div>
  );
};

export default FormContainer;
