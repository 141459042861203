import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTiktok, faLinkedin, faYoutube } from '@fortawesome/free-brands-svg-icons';
import './Footer.scss';


const Footer = () => {
    return (
      <footer>
        <section>
          <article>
            <h2>Web Evolution</h2>
            <p>
              Web Evolution est votre partenaire de confiance pour toutes vos solutions web, offrant des services de création de sites, SEO et bien plus encore.
            </p>
          </article>
          <article>
            <ul>
              <li><a href="https://web-evolution.pro/mentions-legales/">Mentions Légales</a></li>
              <li><a href="https://web-evolution.pro/politique-de-confidentialite-des-donnees/">Politique de confidentialité</a></li>
              <li><a href="https://web-evolution.pro/conditions-generale-de-vente/">Conditions Générales de Vente</a></li>
              <li><a href="https://web-evolution.pro/retour-et-remboursement/">Retour et Remboursement</a></li>
              <li><a href="https://web-evolution.pro/plan-du-site/">Plan du site</a></li>
            </ul>
          </article>
          <article>
            <h2>Contact</h2>
            <ul>
              <li><a href="tel:+33698156851">Téléphone : +33 698 156 851</a></li>
              <li><a href="https://web-evolution.pro/contact/">Email : Nous contacter</a></li>
              <li>Adresse : 34410 Serignan</li>
            </ul>
            <div>
              <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faLinkedin} className='icon' />
              </a>
              <a href="https://youtube.com" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faYoutube} className='icon' />
              </a>
              <a href="https://tiktok.com" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faTiktok} className='icon' />
              </a>
            </div>
          </article>
        </section>
        <section>
          <small>
            <p>© 2024 Web Evolution. Tous droits réservés.</p>
            <p>Web Evolution est une marque déposée. Toute reproduction ou utilisation sans autorisation est strictement interdite.</p>
          </small>
        </section>
      </footer>
    )
}

export default Footer;