import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateFormData } from '../../redux/formSlice';
import '../FormContainer.scss';

const FormStep1 = ({ nextStep }) => {
  const typeProject = useSelector((state) => state.form.typeProject);
  const dispatch = useDispatch();

  const handleChange = (e) => {
    const { value } = e.target;
    dispatch(updateFormData({ typeProject: value }));
  };

  const handleNext = () => {
    nextStep();
  };

  return (
    <div className="formStep">
      <h2>Quel est le type de projet que vous souhaitez réaliser ?</h2>
      <section className='formStep-element'>
        <div>
          <label>
            <input
              type="radio"
              name="typeProject"
              value="branding"
              checked={typeProject === 'branding'}
              onChange={handleChange}
            />
            Branding
          </label>
          <label>
            <input
              type="radio"
              name="typeProject"
              value="ui_ux"
              checked={typeProject === 'ui_ux'}
              onChange={handleChange}
            />
            UI/UX
          </label>
          <label>
            <input
              type="radio"
              name="typeProject"
              value="marketing"
              checked={typeProject === 'marketing'}
              onChange={handleChange}
            />
            Marketing Digital
          </label>
          <label>
            <input
              type="radio"
              name="typeProject"
              value="seo"
              checked={typeProject === 'seo'}
              onChange={handleChange}
            />
            SEO
          </label>
          <label>
            <input
              type="radio"
              name="typeProject"
              value="website"
              checked={typeProject === 'website'}
              onChange={handleChange}
            />
            Site Internet
          </label>
          <label>
            <input
              type="radio"
              name="typeProject"
              value="maintenance"
              checked={typeProject === 'maintenance'}
              onChange={handleChange}
            />
            Maintenance
          </label>
        </div>
      </section>
      <section className="buttons">
        {typeProject && (
          <button onClick={handleNext}>Suivant</button>
        )}  
      </section>
    </div>
  );
};

export default FormStep1;
