import React from 'react';
import logo from '../../assets/web-evolution-logo-complet-dark.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTiktok, faLinkedin, faYoutube } from '@fortawesome/free-brands-svg-icons';
import './Header.scss';


const Header = () => {
    return (
      <header>
        <section>
          <div>
            <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faLinkedin} className='icon' />
            </a>
            <a href="https://youtube.com" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faYoutube} className='icon' />
            </a>
            <a href="https://tiktok.com" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faTiktok} className='icon' />
            </a>
          </div>
        </section>
        <section>
          <div>
            <a href="https://web-evolution.pro">
              <img src={logo} alt="Web Evolution Logo" />
            </a>
          </div>
          <nav>
            <ul>
              <li><a href="https://web-evolution.pro">Site Internet</a></li>
              <li><a href="https://web-evolution.pro/contact">Contact</a></li>
            </ul>
          </nav>
        </section>
      </header>
    );
  };


export default Header;
