import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateFormData } from '../../redux/formSlice';

const FormStep2Website = ({ nextStep, previousStep }) => {
  const dispatch = useDispatch();

  const websiteDetails = useSelector((state) => state.form.websiteDetails);

  const handleChange = (e) => {
    const { name, type, checked, value } = e.target;
    let newWebsiteDetails;

    if (type === 'checkbox') {
      newWebsiteDetails = {
        ...websiteDetails,
        features: {
          ...websiteDetails.features,
          [name]: checked,
        },
      };
    } else {
      newWebsiteDetails = {
        ...websiteDetails,
        [name]: value,
      };
      
      if (name === 'siteType') {
        newWebsiteDetails = {
          ...newWebsiteDetails,
          customSiteType: '',
          numberOfPages: '',
          customNumberOfPages: '',
          features: {
            contactForm: false,
            onlinePayment: false,
            bookingModule: false,
            blog: false,
            newsletterSubscription: false,
            imageGallery: false,
            seoOptimization: false,
            trafficAnalytics: false,
            siteSecurity: false,
          },
          designType: '',
          seoOptimizationLevel: '',
          maintenanceContract: '',
        };
      }

      if (name === 'numberOfPages') {
        newWebsiteDetails = {
          ...newWebsiteDetails,
          customNumberOfPages: '',
          features: {
            contactForm: false,
            onlinePayment: false,
            bookingModule: false,
            blog: false,
            newsletterSubscription: false,
            imageGallery: false,
            seoOptimization: false,
            trafficAnalytics: false,
            siteSecurity: false,
          },
          designType: '',
          seoOptimizationLevel: '',
          maintenanceContract: '',
        };
      }
    }

    dispatch(updateFormData({ websiteDetails: newWebsiteDetails }));
  };

  const handleNext = () => {
    dispatch(updateFormData({ websiteDetails }));
    nextStep();
  };

  const isSiteTypeSelected = !!websiteDetails.siteType;
  const isNumberOfPagesSelected = !!websiteDetails.numberOfPages;
  const isDesignTypeSelected = !!websiteDetails.designType;
  const isSeoOptimizationLevelSelected = !!websiteDetails.seoOptimizationLevel;
  const isMaintenanceContractSelected = !!websiteDetails.maintenanceContract;

  const canProceedToNextStep = 
    isSiteTypeSelected &&
    isNumberOfPagesSelected &&
    isDesignTypeSelected &&
    isSeoOptimizationLevelSelected &&
    isMaintenanceContractSelected;

  return (
    <div className="formStep">
      <div>
        <h2>Quel type de site souhaitez-vous créer ?</h2>
        <section className="formStep-element">
          <div>
            <label>
              <input
                type="radio"
                name="siteType"
                value="vitrine"
                checked={websiteDetails.siteType === 'vitrine'}
                onChange={handleChange}
              />
              Site vitrine
            </label>
            <label>
              <input
                type="radio"
                name="siteType"
                value="ecommerce"
                checked={websiteDetails.siteType === 'ecommerce'}
                onChange={handleChange}
              />
              Site e-commerce
            </label>
            <label>
              <input
                type="radio"
                name="siteType"
                value="blog"
                checked={websiteDetails.siteType === 'blog'}
                onChange={handleChange}
              />
              Blog
            </label>
            <label>
              <input
                type="radio"
                name="siteType"
                value="portfolio"
                checked={websiteDetails.siteType === 'portfolio'}
                onChange={handleChange}
              />
              Portfolio
            </label>
            <label>
              <input
                type="radio"
                name="siteType"
                value="other"
                checked={websiteDetails.siteType === 'other'}
                onChange={handleChange}
              />
              Autre
            </label>
            {websiteDetails.siteType === 'other' && (
              <div className='full'>
                <input
                  type="text"
                  name="customSiteType"
                  value={websiteDetails.customSiteType}
                  onChange={handleChange}
                  placeholder="Veuillez préciser le type de site"
                />
              </div>
            )}
          </div>
        </section>
      </div>

      {websiteDetails.siteType && (
        <div>
          <h3>Combien de pages comprendra votre site ?</h3>
          <section className="formStep-element">
            <div className='four'>
              <label>
                <input
                  type="radio"
                  name="numberOfPages"
                  value="1_5"
                  checked={websiteDetails.numberOfPages === '1_5'}
                  onChange={handleChange}
                />
                1 à 5 pages
              </label>
              <label>
                <input
                  type="radio"
                  name="numberOfPages"
                  value="6_10"
                  checked={websiteDetails.numberOfPages === '6_10'}
                  onChange={handleChange}
                />
                6 à 10 pages
              </label>
              <label>
                <input
                  type="radio"
                  name="numberOfPages"
                  value="more_than_10"
                  checked={websiteDetails.numberOfPages === 'more_than_10'}
                  onChange={handleChange}
                />
                Plus de 10 pages
              </label>
              {websiteDetails.numberOfPages === 'more_than_10' && (
                <div className='full'>
                  <input
                    type="text"
                    name="customNumberOfPages"
                    value={websiteDetails.customNumberOfPages}
                    onChange={handleChange}
                    placeholder="Veuillez préciser le nombre de pages"
                  />
                </div>
              )}
              <label>
                <input
                  type="radio"
                  name="numberOfPages"
                  value="not_sure"
                  checked={websiteDetails.numberOfPages === 'not_sure'}
                  onChange={handleChange}
                />
                Je ne sais pas encore
              </label>
            </div>
          </section>
        </div>
      )}

      {websiteDetails.numberOfPages && (
        <>
          <div>
            <h3>Quelles fonctionnalités souhaitez-vous ajouter à votre site ?</h3>
            <span>Plusieurs choix possible</span>
            <section className="formStep-element">
              <div>
                <label>
                  <input
                    type="checkbox"
                    name="contactForm"
                    checked={websiteDetails.features.contactForm}
                    onChange={handleChange}
                  />
                  Formulaire de contact
                </label>
                <label>
                  <input
                    type="checkbox"
                    name="onlinePayment"
                    checked={websiteDetails.features.onlinePayment}
                    onChange={handleChange}
                  />
                  Système de paiement en ligne (WooCommerce)
                </label>
                <label>
                  <input
                    type="checkbox"
                    name="bookingModule"
                    checked={websiteDetails.features.bookingModule}
                    onChange={handleChange}
                  />
                  Module de réservation en ligne
                </label>
                <label>
                  <input
                    type="checkbox"
                    name="blog"
                    checked={websiteDetails.features.blog}
                    onChange={handleChange}
                  />
                  Blog
                </label>
                <label>
                  <input
                    type="checkbox"
                    name="newsletterSubscription"
                    checked={websiteDetails.features.newsletterSubscription}
                    onChange={handleChange}
                  />
                  Système d’abonnement à une newsletter
                </label>
                <label>
                  <input
                    type="checkbox"
                    name="imageGallery"
                    checked={websiteDetails.features.imageGallery}
                    onChange={handleChange}
                  />
                  Galerie d’images
                </label>
                <label>
                  <input
                    type="checkbox"
                    name="seoOptimization"
                    checked={websiteDetails.features.seoOptimization}
                    onChange={handleChange}
                  />
                  Référencement SEO (optimisation pour Google)
                </label>
                <label>
                  <input
                    type="checkbox"
                    name="trafficAnalytics"
                    checked={websiteDetails.features.trafficAnalytics}
                    onChange={handleChange}
                  />
                  Système d’analyse de trafic (Google Analytics)
                </label>
                <label>
                  <input
                    type="checkbox"
                    name="siteSecurity"
                    checked={websiteDetails.features.siteSecurity}
                    onChange={handleChange}
                  />
                  Sécurisation du site (SSL, plugin de sécurité)
                </label>
              </div>
            </section>
          </div>

          <div>
            <h3>Quel type de design souhaitez-vous ?</h3>
            <section className="formStep-element">
              <div>
                <label>
                  <input
                    type="radio"
                    name="designType"
                    value="predefined"
                    checked={websiteDetails.designType === 'predefined'}
                    onChange={handleChange}
                  />
                  Modèle préconçu (modèle simple)
                </label>
                <label>
                  <input
                    type="radio"
                    name="designType"
                    value="customized"
                    checked={websiteDetails.designType === 'customized'}
                    onChange={handleChange}
                  />
                  Design personnalisé (adapté à votre charte graphique)
                </label>
                <label>
                  <input
                    type="radio"
                    name="designType"
                    value="bespoke"
                    checked={websiteDetails.designType === 'bespoke'}
                    onChange={handleChange}
                  />
                  Design sur mesure (entièrement créé à partir de vos besoins)
                </label>
              </div>
            </section>
          </div>

          <div>
            <h3>Souhaitez-vous une optimisation SEO ?</h3>
            <section className="formStep-element">
              <div>
                <label>
                  <input
                    type="radio"
                    name="seoOptimizationLevel"
                    value="none"
                    checked={websiteDetails.seoOptimizationLevel === 'none'}
                    onChange={handleChange}
                  />
                  Non, pas besoin
                </label>
                <label>
                  <input
                    type="radio"
                    name="seoOptimizationLevel"
                    value="basic"
                    checked={websiteDetails.seoOptimizationLevel === 'basic'}
                    onChange={handleChange}
                  />
                  Oui, optimisation basique (balises, méta descriptions)
                </label>
                <label>
                  <input
                    type="radio"
                    name="seoOptimizationLevel"
                    value="advanced"
                    checked={websiteDetails.seoOptimizationLevel === 'advanced'}
                    onChange={handleChange}
                  />
                  Oui, optimisation avancée (audit SEO, optimisation des pages)
                </label>
              </div>
            </section>
          </div>

          <div>
            <h3>Souhaitez-vous un contrat de maintenance ?</h3>
            <section className="formStep-element">
              <div>
                <label>
                  <input
                    type="radio"
                    name="maintenanceContract"
                    value="none"
                    checked={websiteDetails.maintenanceContract === 'none'}
                    onChange={handleChange}
                  />
                  Non, je gérerai la maintenance moi-même
                </label>
                <label>
                  <input
                    type="radio"
                    name="maintenanceContract"
                    value="standard"
                    checked={websiteDetails.maintenanceContract === 'standard'}
                    onChange={handleChange}
                  />
                  Oui, maintenance standard (mises à jour et sécurité)
                </label>
                <label>
                  <input
                    type="radio"
                    name="maintenanceContract"
                    value="advanced"
                    checked={websiteDetails.maintenanceContract === 'advanced'}
                    onChange={handleChange}
                  />
                  Oui, maintenance avancée (ajouts de fonctionnalités, surveillance)
                </label>
              </div>
            </section>
          </div>
        </>
      )}

      <section className="buttons">
        <button onClick={previousStep} className='back'>Précédent</button>
        {canProceedToNextStep && (
          <button onClick={handleNext}>Suivant</button>
        )}
      </section>
    </div>
  );
};

export default FormStep2Website;