import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  currentStep: 1,
  typeProject: '',
  brandingDetails: {
    logoCreation: false,
    graphicCharter: false,
    marketingMaterials: false,
    logoVectorization: false,
    existingVisualIdentity: '',
  },
  uiUxDetails: {
    supportType: '',
    wireframes: false,
    highFidelityMockups: false,
    interactivePrototype: false,
    userTesting: false,
  },
  marketingDetails: {
    contentStrategy: false,
    inboundMarketing: false,
    socialMediaAds: false,
    emailCampaigns: false,
    communityManagement: false,
    fullManagement: '',
    specificServices: {
      editorialPlanning: false,
      keywordResearch: false,
      visualContentCreation: false,
      blogContentCreation: false,
      contentSeoOptimization: false,
      landingPageCreation: false,
      marketingAutomation: false,
      adVisualCreation: false,
      emailContentCreation: false,
      emailTemplateDesign: false,
      emailAutomation: false,
      emailPersonalization: false,
      crmIntegration: false,
      socialContentCreation: false,
      commentModeration: false,
      interactionManagement: false,
    },
  },
  seoDetails: {
    auditSeo: false,
    htmlOptimization: false,
    contentStrategy: false,
    currentState: '',
    auditType: '',
  },
  websiteDetails: {
    siteType: '',
    customSiteType: '',
    numberOfPages: '',
    customNumberOfPages: '',
    features: {
      contactForm: false,
      onlinePayment: false,
      bookingModule: false,
      blog: false,
      newsletterSubscription: false,
      imageGallery: false,
      seoOptimization: false,
      trafficAnalytics: false,
      siteSecurity: false,
    },
    designType: '',
    seoOptimizationLevel: '',
    maintenanceContract: '',
  },
  maintenanceDetails: {
    maintenanceType: {
      securityUpdates: false,
      dataBackup: false,
      newFeatures: false,
      performanceMonitoring: false,
    },
    existingContract: '',
  },
  step3Details: {
    budget: '',
    projectDuration: '',
    contactInfo: {
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      contactPreference: '',
      contactMethod: '',
      hesitationReason: {
        highPrice: false,
        lackOfTrust: false,
        lackOfTransparency: false,
        complexityOfOffer: false,
        longTermCommitment: false,
        hiddenFees: false,
        lackOfDifferentiation: false,
        noGuarantees: false,
        insufficientSupport: false,
        perceivedRisk: false,
        other: '',
      },
    },
  },
  totalPrice: 0,
};

export const formSlice = createSlice({
  name: 'form',
  initialState,
  reducers: {
    updateFormData: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    resetFormData: () => initialState,
    updateTotalPrice: (state, action) => {
      state.totalPrice = action.payload;
    },
  },
});

export const { updateFormData, resetFormData, updateTotalPrice } = formSlice.actions;
export default formSlice.reducer;
