import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateFormData } from '../../redux/formSlice';

const FormStep2UiUx = ({ nextStep, previousStep }) => {
  const dispatch = useDispatch();

  const uiUxDetails = useSelector((state) => state.form.uiUxDetails);

  const handleChange = (e) => {
    const { name, type, checked, value } = e.target;
    let newUiUxDetails;
  
    if (type === 'checkbox') {
      newUiUxDetails = {
        ...uiUxDetails,
        [name]: checked,
      };
    } else {
      newUiUxDetails = {
        ...uiUxDetails,
        [name]: value,
      };
    }
  
    dispatch(updateFormData({ uiUxDetails: newUiUxDetails }));
  };
  

  const handleNext = () => {
    dispatch(updateFormData({ uiUxDetails }));
    nextStep();
  };

  const isSupportTypeSelected = !!uiUxDetails.supportType;
  const areDeliverablesSelected = uiUxDetails.supportType && (
    uiUxDetails.wireframes ||
    uiUxDetails.highFidelityMockups ||
    uiUxDetails.interactivePrototype ||
    uiUxDetails.userTesting
  );

  return (
    <div className="formStep">
      <div>
        <h2>Quel est le type de support pour lequel vous souhaitez concevoir l'interface ?</h2>
        <section className="formStep-element">
          <div className='four'>
            <label>
              <input
                type="radio"
                name="supportType"
                value="site_web"
                checked={uiUxDetails.supportType === 'site_web'}
                onChange={handleChange}
              />
              Site web
            </label>
            <label>
              <input
                type="radio"
                name="supportType"
                value="application_mobile"
                checked={uiUxDetails.supportType === 'application_mobile'}
                onChange={handleChange}
              />
              Application mobile
            </label>
            <label>
              <input
                type="radio"
                name="supportType"
                value="application_saas"
                checked={uiUxDetails.supportType === 'application_saas'}
                onChange={handleChange}
              />
              Application SaaS
            </label>
            <label>
              <input
                type="radio"
                name="supportType"
                value="autre"
                checked={uiUxDetails.supportType === 'autre'}
                onChange={handleChange}
              />
              Autre
            </label>
          </div>
        </section>
      </div>

      {uiUxDetails.supportType && (
        <div>
          <h3>Quels livrables attendez-vous ?</h3>
          <span>Plusieurs choix possible</span>
          <section className="formStep-element">
            <div className='four'>
              <label>
                <input
                  type="checkbox"
                  name="wireframes"
                  checked={uiUxDetails.wireframes}
                  onChange={handleChange}
                />
                Wireframes (maquettes basse fidélité)
              </label>
              <label>
                <input
                  type="checkbox"
                  name="highFidelityMockups"
                  checked={uiUxDetails.highFidelityMockups}
                  onChange={handleChange}
                />
                Maquettes haute fidélité
              </label>
              <label>
                <input
                  type="checkbox"
                  name="interactivePrototype"
                  checked={uiUxDetails.interactivePrototype}
                  onChange={handleChange}
                />
                Prototype interactif
              </label>
              <label>
                <input
                  type="checkbox"
                  name="userTesting"
                  checked={uiUxDetails.userTesting}
                  onChange={handleChange}
                />
                Test utilisateur (améliorer l'UX grâce à des tests)
              </label>
            </div>
          </section>
        </div>
      )}

      <section className="buttons">
        <button onClick={previousStep} className='back'>Précédent</button>
        {isSupportTypeSelected && areDeliverablesSelected && (
          <button onClick={handleNext}>Suivant</button>
        )}  
      </section>
    </div>
  );
};

export default FormStep2UiUx;