import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateFormData } from '../../redux/formSlice';

const FormStep2Marketing = ({ nextStep, previousStep }) => {
  const dispatch = useDispatch();

  const marketingDetails = useSelector((state) => state.form.marketingDetails);

  const handleChange = (e) => {
    const { name, type, checked, value } = e.target;
    let newMarketingDetails;
  
    if (type === 'checkbox') {
      if (name in marketingDetails.specificServices) {
        newMarketingDetails = {
          ...marketingDetails,
          specificServices: {
            ...marketingDetails.specificServices,
            [name]: checked,
          },
        };
      } else {
        newMarketingDetails = {
          ...marketingDetails,
          [name]: checked,
        };
      }

      if (!newMarketingDetails.contentStrategy && !newMarketingDetails.inboundMarketing && !newMarketingDetails.socialMediaAds && !newMarketingDetails.emailCampaigns && !newMarketingDetails.communityManagement) {
        newMarketingDetails = {
          ...newMarketingDetails,
          fullManagement: '',
          specificServices: {
            editorialPlanning: false,
            keywordResearch: false,
            visualContentCreation: false,
            blogContentCreation: false,
            contentSeoOptimization: false,
            landingPageCreation: false,
            marketingAutomation: false,
            adVisualCreation: false,
            emailContentCreation: false,
            emailTemplateDesign: false,
            emailAutomation: false,
            emailPersonalization: false,
            crmIntegration: false,
            socialContentCreation: false,
            commentModeration: false,
            interactionManagement: false,
          },
        };
      }
    } else {
      newMarketingDetails = {
        ...marketingDetails,
        [name]: value,
      };
    }
  
    dispatch(updateFormData({ marketingDetails: newMarketingDetails }));
  };
  

  const handleNext = () => {
    dispatch(updateFormData({ marketingDetails }));
    nextStep();
  };

  const isAnyPrioritySelected = marketingDetails.contentStrategy || marketingDetails.inboundMarketing || marketingDetails.socialMediaAds || marketingDetails.emailCampaigns || marketingDetails.communityManagement;
  const isFullManagementSelected = marketingDetails.fullManagement === 'complete' || marketingDetails.fullManagement === 'specific';

  const isSpecificServicesSelected = marketingDetails.fullManagement !== 'specific' ||
    Object.values(marketingDetails.specificServices || {}).some((value) => value);



  return (
    <div className="formStep">
      <div>
        <h2>Quelles sont les priorités pour votre projet marketing ?</h2>
        <span>Plusieurs choix possible</span>
        <section className="formStep-element">
          <div>
            <label>
              <input
                type="checkbox"
                name="contentStrategy"
                checked={marketingDetails.contentStrategy}
                onChange={handleChange}
              />
              Stratégie de contenu
            </label>
            <label>
              <input
                type="checkbox"
                name="inboundMarketing"
                checked={marketingDetails.inboundMarketing}
                onChange={handleChange}
              />
              Inbound marketing
            </label>
            <label>
              <input
                type="checkbox"
                name="socialMediaAds"
                checked={marketingDetails.socialMediaAds}
                onChange={handleChange}
              />
              Publicité sur les réseaux sociaux
            </label>
            <label>
              <input
                type="checkbox"
                name="emailCampaigns"
                checked={marketingDetails.emailCampaigns}
                onChange={handleChange}
              />
              Campagnes d'e-mailing
            </label>
            <label>
              <input
                type="checkbox"
                name="communityManagement"
                checked={marketingDetails.communityManagement}
                onChange={handleChange}
              />
              Gestion de communauté
            </label>
          </div>
        </section>
      </div>

      {isAnyPrioritySelected && (
        <div>
          <h3>Souhaitez-vous une gestion complète ou des services spécifiques ?</h3>
          <section className="formStep-element">
            <div>
              <label>
                <input
                  type="radio"
                  name="fullManagement"
                  value="complete"
                  checked={marketingDetails.fullManagement === 'complete'}
                  onChange={handleChange}
                />
                Gestion complète
              </label>
              <label>
                <input
                  type="radio"
                  name="fullManagement"
                  value="specific"
                  checked={marketingDetails.fullManagement === 'specific'}
                  onChange={handleChange}
                />
                Services spécifiques
              </label>
            </div>
          </section>
        </div>
      )}

      {marketingDetails.fullManagement === 'specific' && (
        <div>
          <h3>Quels services spécifiques souhaitez-vous ?</h3>
          <span>Plusieurs choix possible</span>
          <section className="formStep-element">
            <div>
              {marketingDetails.contentStrategy && (
                <>
                  <label>
                    <input
                      type="checkbox"
                      name="editorialPlanning"
                      checked={marketingDetails.specificServices.editorialPlanning}
                      onChange={handleChange}
                    />
                    Planification éditoriale
                  </label>
                  <label>
                    <input
                      type="checkbox"
                      name="keywordResearch"
                      checked={marketingDetails.specificServices.keywordResearch}
                      onChange={handleChange}
                    />
                    Recherche de mots clés
                  </label>
                  <label>
                    <input
                      type="checkbox"
                      name="visualContentCreation"
                      checked={marketingDetails.specificServices.visualContentCreation}
                      onChange={handleChange}
                    />
                    Création de contenu visuel
                  </label>
                </>
              )}
              {marketingDetails.inboundMarketing && (
                <>
                  <label>
                    <input
                      type="checkbox"
                      name="blogContentCreation"
                      checked={marketingDetails.specificServices.blogContentCreation}
                      onChange={handleChange}
                    />
                    Création de contenus de blog
                  </label>
                  <label>
                    <input
                      type="checkbox"
                      name="contentSeoOptimization"
                      checked={marketingDetails.specificServices.contentSeoOptimization}
                      onChange={handleChange}
                    />
                    Optimisation SEO du contenu
                  </label>
                  <label>
                    <input
                      type="checkbox"
                      name="landingPageCreation"
                      checked={marketingDetails.specificServices.landingPageCreation}
                      onChange={handleChange}
                    />
                    Création de landing page
                  </label>
                  <label>
                    <input
                      type="checkbox"
                      name="marketingAutomation"
                      checked={marketingDetails.specificServices.marketingAutomation}
                      onChange={handleChange}
                    />
                    Automatisation marketing
                  </label>
                </>
              )}
              {marketingDetails.socialMediaAds && (
                <>
                  <label>
                    <input
                      type="checkbox"
                      name="adVisualCreation"
                      checked={marketingDetails.specificServices.adVisualCreation}
                      onChange={handleChange}
                    />
                    Création de visuels publicitaires
                  </label>
                </>
              )}
              {marketingDetails.emailCampaigns && (
                <>
                  <label>
                    <input
                      type="checkbox"
                      name="emailContentCreation"
                      checked={marketingDetails.specificServices.emailContentCreation}
                      onChange={handleChange}
                    />
                    Création de contenu d'email
                  </label>
                  <label>
                    <input
                      type="checkbox"
                      name="emailTemplateDesign"
                      checked={marketingDetails.specificServices.emailTemplateDesign}
                      onChange={handleChange}
                    />
                    Conception de modèle d'email
                  </label>
                  <label>
                    <input
                      type="checkbox"
                      name="emailAutomation"
                      checked={marketingDetails.specificServices.emailAutomation}
                      onChange={handleChange}
                    />
                    Automatisation des emails
                  </label>
                  <label>
                    <input
                      type="checkbox"
                      name="emailPersonalization"
                      checked={marketingDetails.specificServices.emailPersonalization}
                      onChange={handleChange}
                    />
                    Personnalisation des emails
                  </label>
                  <label>
                    <input
                      type="checkbox"
                      name="crmIntegration"
                      checked={marketingDetails.specificServices.crmIntegration}
                      onChange={handleChange}
                    />
                    Intégration CRM
                  </label>
                </>
              )}
              {marketingDetails.communityManagement && (
                <>
                  <label>
                    <input
                      type="checkbox"
                      name="socialContentCreation"
                      checked={marketingDetails.specificServices.socialContentCreation}
                      onChange={handleChange}
                    />
                    Création de contenu pour les réseaux sociaux
                  </label>
                  <label>
                    <input
                      type="checkbox"
                      name="commentModeration"
                      checked={marketingDetails.specificServices.commentModeration}
                      onChange={handleChange}
                    />
                    Modération des commentaires
                  </label>
                  <label>
                    <input
                      type="checkbox"
                      name="interactionManagement"
                      checked={marketingDetails.specificServices.interactionManagement}
                      onChange={handleChange}
                    />
                    Gestion des interactions
                  </label>
                  <label>
                    <input
                      type="checkbox"
                      name="emailPersonalization"
                      checked={marketingDetails.specificServices.emailPersonalization}
                      onChange={handleChange}
                    />
                    Personnalisation des emails
                  </label>
                  <label>
                    <input
                      type="checkbox"
                      name="crmIntegration"
                      checked={marketingDetails.specificServices.crmIntegration}
                      onChange={handleChange}
                    />
                    Intégration CRM
                  </label>
                </>
              )}
            </div>
          </section>
        </div>
      )}

      <section className="buttons">
        <button onClick={previousStep} className='back'>Précédent</button>
        {isAnyPrioritySelected && isFullManagementSelected && isSpecificServicesSelected && (
          <button onClick={handleNext}>Suivant</button>
        )}
      </section>
    </div>
  );
};

export default FormStep2Marketing;