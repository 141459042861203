import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateFormData } from '../../redux/formSlice';

const FormStep2Seo = ({ nextStep, previousStep }) => {
  const dispatch = useDispatch();

  const seoDetails = useSelector((state) => state.form.seoDetails);

  const handleChange = (e) => {
    const { name, type, checked, value } = e.target;
    let newSeoDetails;

    if (type === 'checkbox') {
      newSeoDetails = {
        ...seoDetails,
        [name]: checked,
      };
    } else {
      newSeoDetails = {
        ...seoDetails,
        [name]: value,
      };
    }

    if (name === 'auditSeo' && !checked) {
      newSeoDetails = {
        ...newSeoDetails,
        currentState: '',
        auditType: '',
      };
    }

    dispatch(updateFormData({ seoDetails: newSeoDetails }));
  };

  const handleNext = () => {
    dispatch(updateFormData({ seoDetails }));
    nextStep();
  };

  const isAnyServiceSelected = seoDetails.auditSeo || seoDetails.htmlOptimization || seoDetails.contentStrategy;
  const isCurrentStateSelected = !seoDetails.auditSeo || seoDetails.currentState !== '';
  const isAuditTypeSelected = seoDetails.currentState !== 'advanced_audit' || seoDetails.auditType !== '';


  return (
    <div className="formStep">
      <div>
        <h2>Quels services SEO recherchez-vous ?</h2>
        <span>Plusieurs choix possible</span>
        <section className="formStep-element">
          <div>
            <label>
              <input
                type="checkbox"
                name="auditSeo"
                checked={seoDetails.auditSeo}
                onChange={handleChange}
              />
              Audit SEO de votre site actuel
            </label>
            <label>
              <input
                type="checkbox"
                name="htmlOptimization"
                checked={seoDetails.htmlOptimization}
                onChange={handleChange}
              />
              Optimisation des balises HTML (titres, méta-descriptions)
            </label>
            <label>
              <input
                type="checkbox"
                name="contentStrategy"
                checked={seoDetails.contentStrategy}
                onChange={handleChange}
              />
              Stratégie de contenu optimisé SEO
            </label>
          </div>
        </section>
      </div>

      {seoDetails.auditSeo && (
        <div>
          <h3>Quel est l’état actuel de votre SEO ?</h3>
          <section className="formStep-element">
            <div className='four'>
              <label>
                <input
                  type="radio"
                  name="currentState"
                  value="no_idea"
                  checked={seoDetails.currentState === 'no_idea'}
                  onChange={handleChange}
                />
                Je ne sais pas
              </label>
              <label>
                <input
                  type="radio"
                  name="currentState"
                  value="not_implemented"
                  checked={seoDetails.currentState === 'not_implemented'}
                  onChange={handleChange}
                />
                Pas encore mis en place
              </label>
              <label>
                <input
                  type="radio"
                  name="currentState"
                  value="needs_improvement"
                  checked={seoDetails.currentState === 'needs_improvement'}
                  onChange={handleChange}
                />
                SEO existant mais à améliorer
              </label>
              <label>
                <input
                  type="radio"
                  name="currentState"
                  value="advanced_audit"
                  checked={seoDetails.currentState === 'advanced_audit'}
                  onChange={handleChange}
                />
                SEO avancé, besoin d’un audit
              </label>
            </div>
          </section>
        </div>
      )}

      {seoDetails.currentState === 'advanced_audit' && (
        <div>
          <h3>De quel type d'audit avez-vous besoin ?</h3>
          <section className="formStep-element">
            <div className='two'>
              <label>
                <input
                  type="radio"
                  name="auditType"
                  value="simple"
                  checked={seoDetails.auditType === 'simple'}
                  onChange={handleChange}
                />
                Audit simple : uniquement SEO
              </label>
              <label>
                <input
                  type="radio"
                  name="auditType"
                  value="advanced"
                  checked={seoDetails.auditType === 'advanced'}
                  onChange={handleChange}
                />
                Audit avancé
              </label>
            </div>
          </section>
        </div>
      )}

      <section className="buttons">
        <button onClick={previousStep} className='back'>Précédent</button>
        {isAnyServiceSelected && isCurrentStateSelected && isAuditTypeSelected && (
          <button onClick={handleNext}>Suivant</button>
        )}    
      </section>
    </div>
  );
};

export default FormStep2Seo;