import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateFormData } from '../../redux/formSlice';

const FormStep2Maintenance = ({ nextStep, previousStep }) => {
  const dispatch = useDispatch();

  const maintenanceDetails = useSelector((state) => state.form.maintenanceDetails);

  const handleChange = (e) => {
    const { name, type, checked, value } = e.target;
    let newMaintenanceDetails;

    if (type === 'checkbox') {
      newMaintenanceDetails = {
        ...maintenanceDetails,
        maintenanceType: {
          ...maintenanceDetails.maintenanceType,
          [name]: checked,
        },
      };
      
      if (!Object.values(newMaintenanceDetails.maintenanceType).some((v) => v)) {
        newMaintenanceDetails = {
          ...newMaintenanceDetails,
          existingContract: '',
        };
      }
    } else {
      newMaintenanceDetails = {
        ...maintenanceDetails,
        [name]: value,
      };
    }

    dispatch(updateFormData({ maintenanceDetails: newMaintenanceDetails }));
  };

  const handleNext = () => {
    dispatch(updateFormData({ maintenanceDetails }));
    nextStep();
  };

  const isAnyMaintenanceTypeSelected = Object.values(maintenanceDetails.maintenanceType).some((value) => value);
  const isExistingContractSelected = maintenanceDetails.existingContract === 'yes' || maintenanceDetails.existingContract === 'no';


  return (
    <div className="formStep">
      <div>
        <h2>Quel type de maintenance recherchez-vous ?</h2>
        <span>Plusieurs choix possible</span>
        <section className="formStep-element">
          <div className='four'>
            <label>
              <input
                type="checkbox"
                name="securityUpdates"
                checked={maintenanceDetails.maintenanceType.securityUpdates}
                onChange={handleChange}
              />
              Mises à jour de sécurité régulières
            </label>
            <label>
              <input
                type="checkbox"
                name="dataBackup"
                checked={maintenanceDetails.maintenanceType.dataBackup}
                onChange={handleChange}
              />
              Sauvegarde et gestion des données
            </label>
            <label>
              <input
                type="checkbox"
                name="newFeatures"
                checked={maintenanceDetails.maintenanceType.newFeatures}
                onChange={handleChange}
              />
              Ajout de nouvelles fonctionnalités
            </label>
            <label>
              <input
                type="checkbox"
                name="performanceMonitoring"
                checked={maintenanceDetails.maintenanceType.performanceMonitoring}
                onChange={handleChange}
              />
              Surveillance des performances du site
            </label>
          </div>
        </section>
      </div>

      {isAnyMaintenanceTypeSelected && (
        <div>
          <h3>Avez-vous déjà un contrat de maintenance pour ce site ?</h3>
          <section className="formStep-element">
            <div className='two'>
              <label>
                <input
                  type="radio"
                  name="existingContract"
                  value="yes"
                  checked={maintenanceDetails.existingContract === 'yes'}
                  onChange={handleChange}
                />
                Oui
              </label>
              <label>
                <input
                  type="radio"
                  name="existingContract"
                  value="no"
                  checked={maintenanceDetails.existingContract === 'no'}
                  onChange={handleChange}
                />
                Non, c’est une première demande
              </label>
            </div>
          </section>
        </div>
      )}

      <section className="buttons">
        <button onClick={previousStep} className='back'>Précédent</button>
        {isAnyMaintenanceTypeSelected && isExistingContractSelected && (
          <button onClick={handleNext}>Suivant</button>
        )}
      </section>
    </div>
  );
};

export default FormStep2Maintenance;