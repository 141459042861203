import React from 'react';
import axios from 'axios';

import { useDispatch, useSelector } from 'react-redux';
import { updateFormData } from '../../redux/formSlice';

const FormStep3 = ({ previousStep, submitForm }) => {
  const dispatch = useDispatch();
  const formData = useSelector((state) => state.form);
  const step3Details = useSelector((state) => state.form.step3Details);

  const handleDownloadPDF = async () => {
    try {
      const response = await axios.post('/api/projects/download-pdf', formData, {
        responseType: 'blob',
      });

      const currentDate = new Date();
      const formattedDate2 = `${currentDate.getFullYear()}_${String(currentDate.getMonth() + 1).padStart(2, '0')}_${String(currentDate.getDate()).padStart(2, '0')}`;

  
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `estimation-${formattedDate2}-${formData.step3Details.contactInfo.lastName}.pdf`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Erreur lors du téléchargement du PDF :', error);
    }
  };

  const handleChange = (e) => {
    const { name, type, value, checked } = e.target;
    let newStep3Details;

    if (name in step3Details.contactInfo.hesitationReason) {
      newStep3Details = {
        ...step3Details,
        contactInfo: {
          ...step3Details.contactInfo,
          hesitationReason: {
            ...step3Details.contactInfo.hesitationReason,
            [name]: type === 'checkbox' ? checked : value,
          },
        },
      };
    } else if (name in step3Details.contactInfo) {
      newStep3Details = {
        ...step3Details,
        contactInfo: {
          ...step3Details.contactInfo,
          [name]: value,
        },
      };
    } else {
      newStep3Details = {
        ...step3Details,
        [name]: value,
      };
    }

    dispatch(updateFormData({ step3Details: newStep3Details }));
  };

  const handleSubmit = () => {
    dispatch(updateFormData({ step3Details }));
    submitForm();
  };

  const isBudgetSelected = !!step3Details.budget;
const isProjectDurationSelected = !!step3Details.projectDuration;
const isContactPreferenceSelected = !!step3Details.contactInfo.contactPreference;
const isContactInfoComplete =
  step3Details.contactInfo.firstName &&
  step3Details.contactInfo.lastName &&
  step3Details.contactInfo.email &&
  step3Details.contactInfo.phoneNumber &&
  isContactPreferenceSelected;

  return (
    <div className="formStep">
      <div>
        <h2>Quel est votre budget approximatif pour ce projet ?</h2>
        <section className="formStep-element">
          <div className='four'>
            <label>
              <input
                type="radio"
                name="budget"
                value="less_than_1000"
                checked={step3Details.budget === 'less_than_1000'}
                onChange={handleChange}
              />
              &lt; 1000 €
            </label>
            <label>
              <input
                type="radio"
                name="budget"
                value="1000_3000"
                checked={step3Details.budget === '1000_3000'}
                onChange={handleChange}
              />
              1000 € - 3000 €
            </label>
            <label>
              <input
                type="radio"
                name="budget"
                value="3000_5000"
                checked={step3Details.budget === '3000_5000'}
                onChange={handleChange}
              />
              3000 € - 5000 €
            </label>
            <label>
              <input
                type="radio"
                name="budget"
                value="more_than_5000"
                checked={step3Details.budget === 'more_than_5000'}
                onChange={handleChange}
              />
              Plus de 5000 €
            </label>
          </div>
        </section>
      </div>

      {isBudgetSelected && (
        <div>
          <h3>Sous combien de temps souhaitez-vous voir ce projet réalisé ?</h3>
          <section className="formStep-element">
            <div className='four'>
              <label>
                <input
                  type="radio"
                  name="projectDuration"
                  value="1_month"
                  checked={step3Details.projectDuration === '1_month'}
                  onChange={handleChange}
                />
                1 mois
              </label>
              <label>
                <input
                  type="radio"
                  name="projectDuration"
                  value="2_3_months"
                  checked={step3Details.projectDuration === '2_3_months'}
                  onChange={handleChange}
                />
                2-3 mois
              </label>
              <label>
                <input
                  type="radio"
                  name="projectDuration"
                  value="4_6_months"
                  checked={step3Details.projectDuration === '4_6_months'}
                  onChange={handleChange}
                />
                4-6 mois
              </label>
              <label>
                <input
                  type="radio"
                  name="projectDuration"
                  value="more_than_6_months"
                  checked={step3Details.projectDuration === 'more_than_6_months'}
                  onChange={handleChange}
                />
                Plus de 6 mois
              </label>
            </div>
          </section>
        </div>
      )}

      {isProjectDurationSelected && (
        <div>
          <h3>Informations de contact</h3>
          <section className="formStep-element">
            <div className='demi'>
              <label>
                Prénom :
                <input
                  type="text"
                  name="firstName"
                  value={step3Details.contactInfo.firstName}
                  onChange={handleChange}
                />
              </label>
              <label>
                Nom :
                <input
                  type="text"
                  name="lastName"
                  value={step3Details.contactInfo.lastName}
                  onChange={handleChange}
                />
              </label>
              <label>
                Email :
                <input
                  type="email"
                  name="email"
                  value={step3Details.contactInfo.email}
                  onChange={handleChange}
                />
              </label>
              <label>
                Numéro de téléphone :
                <input
                  type="tel"
                  name="phoneNumber"
                  value={step3Details.contactInfo.phoneNumber}
                  onChange={handleChange}
                />
              </label>
            </div>
          </section>
          <h3>Souhaitez-vous être recontacté pour discuter de votre projet ?</h3>
          <section className="formStep-element">
            <div className='two'>
              <label>
                <input
                  type="radio"
                  name="contactPreference"
                  value="yes"
                  checked={step3Details.contactInfo.contactPreference === 'yes'}
                  onChange={handleChange}
                />
                Oui
              </label>
              <label>
                <input
                  type="radio"
                  name="contactPreference"
                  value="no"
                  checked={step3Details.contactInfo.contactPreference === 'no'}
                  onChange={handleChange}
                />
                Non, j'ai besoin de réfléchir
              </label>
            </div>
          </section>
          {step3Details.contactInfo.contactPreference === 'yes' && (
            <div>
              <h3>Par quel moyen souhaitez-vous être contacté ?</h3>
              <section className="formStep-element">
                <div>
                  <label>
                    <input
                      type="radio"
                      name="contactMethod"
                      value="phone"
                      checked={step3Details.contactInfo.contactMethod === 'phone'}
                      onChange={handleChange}
                    />
                    Téléphone
                  </label>
                  <label>
                    <input
                      type="radio"
                      name="contactMethod"
                      value="email"
                      checked={step3Details.contactInfo.contactMethod === 'email'}
                      onChange={handleChange}
                    />
                    Email
                  </label>
                  <label>
                    <input
                      type="radio"
                      name="contactMethod"
                      value="video_meeting"
                      checked={step3Details.contactInfo.contactMethod === 'video_meeting'}
                      onChange={handleChange}
                    />
                    Prendre RDV en visio
                  </label>
                </div>
              </section>
            </div>
          )}
          {step3Details.contactInfo.contactPreference === 'no' && (
            <div>
              <h3>Qu'est-ce qui vous freine ?</h3>
              <span>Plusieurs choix possible</span>
              <section className="formStep-element">
                <div>
                  <label>
                    <input
                      type="checkbox"
                      name="highPrice"
                      checked={step3Details.contactInfo.hesitationReason.highPrice}
                      onChange={handleChange}
                    />
                    Prix trop élevé
                  </label>
                  <label>
                    <input
                      type="checkbox"
                      name="lackOfTrust"
                      checked={step3Details.contactInfo.hesitationReason.lackOfTrust}
                      onChange={handleChange}
                    />
                    Manque de confiance
                  </label>
                  <label>
                    <input
                      type="checkbox"
                      name="lackOfTransparency"
                      checked={step3Details.contactInfo.hesitationReason.lackOfTransparency}
                      onChange={handleChange}
                    />
                    Manque de transparence
                  </label>
                  <label>
                    <input
                      type="checkbox"
                      name="complexityOfOffer"
                      checked={step3Details.contactInfo.hesitationReason.complexityOfOffer}
                      onChange={handleChange}
                    />
                    Complexité de l'offre
                  </label>
                  <label>
                    <input
                      type="checkbox"
                      name="longTermCommitment"
                      checked={step3Details.contactInfo.hesitationReason.longTermCommitment}
                      onChange={handleChange}
                    />
                    Engagement à long terme
                  </label>
                  <label>
                    <input
                      type="checkbox"
                      name="hiddenFees"
                      checked={step3Details.contactInfo.hesitationReason.hiddenFees}
                      onChange={handleChange}
                    />
                    Frais cachés
                  </label>
                  <label>
                    <input
                      type="checkbox"
                      name="lackOfDifferentiation"
                      checked={step3Details.contactInfo.hesitationReason.lackOfDifferentiation}
                      onChange={handleChange}
                    />
                    Peu de différenciation
                  </label>
                  <label>
                    <input
                      type="checkbox"
                      name="noGuarantees"
                      checked={step3Details.contactInfo.hesitationReason.noGuarantees}
                      onChange={handleChange}
                    />
                    Absence de garanties
                  </label>
                  <label>
                    <input
                      type="checkbox"
                      name="insufficientSupport"
                      checked={step3Details.contactInfo.hesitationReason.insufficientSupport}
                      onChange={handleChange}
                    />
                    Support client insuffisant
                  </label>
                  <label>
                    <input
                      type="checkbox"
                      name="perceivedRisk"
                      checked={step3Details.contactInfo.hesitationReason.perceivedRisk}
                      onChange={handleChange}
                    />
                    Risque perçu
                  </label>
                </div>
              </section>
            </div>
          )}
        </div>
      )}

      {/* <button onClick={previousStep}>Précédent</button>
      <button onClick={handleSubmit}>Envoyer</button>
      <button onClick={handleDownloadPDF}>Télécharger le devis PDF</button> */}

      <section className="buttons">
        {isBudgetSelected && (
          <button onClick={previousStep} className='back'>Précédent</button>
        )}
        {isContactInfoComplete && (
          <>
            <button onClick={handleSubmit}>Envoyer</button>
            <button onClick={handleDownloadPDF}>Télécharger le devis PDF</button>
          </>
        )}
      </section>
    </div>
  );
};

export default FormStep3;