import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { updateFormData } from '../../redux/formSlice';
import '../FormContainer.scss';


const FormStep2Branding = ({ nextStep, previousStep }) => {
  const dispatch = useDispatch();
  const brandingDetails = useSelector((state) => state.form.brandingDetails);

  const handleChange = (e) => {
    const { name, type, checked, value } = e.target;
    let newBrandingDetails;

    if (type === 'checkbox') {
      newBrandingDetails = {
        ...brandingDetails,
        [name]: checked,
      };
      
      if (!newBrandingDetails.logoCreation && !newBrandingDetails.graphicCharter && !newBrandingDetails.marketingMaterials && !newBrandingDetails.logoVectorization) {
        newBrandingDetails = {
          ...newBrandingDetails,
          existingVisualIdentity: '',
        };
      }
    } else {
      newBrandingDetails = {
        ...brandingDetails,
        [name]: value,
      };
    }

    dispatch(updateFormData({ brandingDetails: newBrandingDetails }));
  };

  const handleNext = () => {
    dispatch(updateFormData({ brandingDetails }));
    nextStep();
  };

  const isAnyOptionSelected = brandingDetails.logoCreation || brandingDetails.graphicCharter || brandingDetails.marketingMaterials || brandingDetails.logoVectorization;
  const isExistingVisualIdentitySelected = brandingDetails.existingVisualIdentity === 'yes' || brandingDetails.existingVisualIdentity === 'no';

  return (
    <div className="formStep">
      <div>
        <h2>Quels éléments souhaitez-vous inclure dans votre projet de branding ?</h2>
        <span>Plusieurs choix possible</span>
        <section className="formStep-element">
          <div className='four'>
            <label>
              <input
                type="checkbox"
                name="logoCreation"
                checked={brandingDetails.logoCreation}
                onChange={handleChange}
              />
              Création de logo
            </label>
            <label>
              <input
                type="checkbox"
                name="graphicCharter"
                checked={brandingDetails.graphicCharter}
                onChange={handleChange}
              />
              Charte graphique
            </label>
            <label>
              <input
                type="checkbox"
                name="marketingMaterials"
                checked={brandingDetails.marketingMaterials}
                onChange={handleChange}
              />
              Carte de visite et supports marketing
            </label>
            <label>
              <input
                type="checkbox"
                name="logoVectorization"
                checked={brandingDetails.logoVectorization}
                onChange={handleChange}
              />
              Vectorisation de logo existant
            </label>
          </div>
        </section>
      </div>

      {isAnyOptionSelected && (
        <div>
          <h3>Avez-vous déjà une identité visuelle existante ?</h3>
          <section className="formStep-element">
            <div className='two'>
              <label>
                <input
                  type="radio"
                  name="existingVisualIdentity"
                  value="yes"
                  checked={brandingDetails.existingVisualIdentity === 'yes'}
                  onChange={handleChange}
                />
                Oui
              </label>
              <label>
                <input
                  type="radio"
                  name="existingVisualIdentity"
                  value="no"
                  checked={brandingDetails.existingVisualIdentity === 'no'}
                  onChange={handleChange}
                />
                Non, je pars de zéro
              </label>
            </div>
          </section>
        </div>
      )}

      <section className="buttons">
        <button onClick={previousStep} className='back'>Précédent</button>
        {isExistingVisualIdentitySelected && (
          <button onClick={handleNext}>Suivant</button>
        )}
      </section>
    </div>
  );
};

export default FormStep2Branding;
